export default {
    methods: {

    /*Metodos de listagem de dados*/
      listarFaturas(){
        this.axios
          .get("/faturas/listar")
          .then((response) => {
            this.faturas = response.data.dados;
            console.log(this.faturas)
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      listarTipoInsumo() {
        this.axios
          .post("/unidades/dados/tipos-insumo/listar")
          .then((response) => {

            this.tiposInsumos = response.data.dados;

            let options = [];
            let desc = {};
            response.data.dados.map((el) => {

              let option = { value: el["id"], label: el["descricao"] };
              desc[el["id"]] = el["descricao"];
              options.push(option);

            });
            this.tipo_insumo = options;
            this.desc_tipo_insumo = desc;

            console.log(this.tipo_insumo)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarEmpresas() {
        this.axios
          .post("/empresas/listar")
          .then((response) => {

            this.Empresas = response.data.dados;

            let options_empresas = [];
            let desc_empresas = {};
            response.data.dados.map((el_empresas) => {

              let option_empresas = { value: el_empresas["id"], label: el_empresas["razao_social"] };
              desc_empresas[el_empresas["id"]] = el_empresas["razao_social"];
              options_empresas.push(option_empresas);

            });
            this.tipos_empresas = options_empresas;
            this.desc_tipos_empresas = desc_empresas;

            console.log(this.desc_tipos_empresas)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarModalidadesTarifarias() {
        this.axios
          .post("/unidades/dados/tipos-modalidade-tarifaria/listar")
          .then((response) => {

            this.Modalidades = response.data.dados;
            this.tiposModalidadesTarifarias = response.data.dados;

            let options_modalidades = [];
            let desc_modalidades = {};
            response.data.dados.map((el_modalidades) => {

              let option_modalidades = { value: el_modalidades["id"], label: el_modalidades["descricao"] };
              desc_modalidades[el_modalidades["id"]] = el_modalidades["descricao"];
              options_modalidades.push(option_modalidades);

            });
            this.tipos_modalidades = options_modalidades;
            this.desc_tipos_modalidades = desc_modalidades;

            console.log(this.desc_tipos_modalidades)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarTiposConcessionarias() {
        this.axios
          .post("/concessionarias/tipos/listar")
          .then((response) => {

            let options_concess = [];
            let desc_concess = {};
            response.data.dados.map((el_concess) => {

              let option_concess = { value: el_concess["id"], label: el_concess["descricao"] };
              desc_concess[el_concess["id"]] = el_concess["descricao"];
              options_concess.push(option_concess);

            });
            this.tipos_concess = options_concess;
            this.desc_tipos_concess = desc_concess;

            console.log(this.desc_tipos_concess)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarPagamento() {
        this.axios
          .post("/unidades/dados/tipos-pagamento/listar")
          .then((response) => {

            let options_pagamento = [];
            let desc_pagamento = {};
            response.data.dados.map((el_pagamento) => {

              let option_pagamento = { value: el_pagamento["id"], label: el_pagamento["descricao"] };
              desc_pagamento[el_pagamento["id"]] = el_pagamento["descricao"];
              options_pagamento.push(option_pagamento);

            });
            this.tipos_pagamento = options_pagamento;
            this.desc_tipos_pagamento = desc_pagamento;

            console.log(this.desc_tipos_pagamento)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarClasseTarifaria() {
        this.axios
          .post("/unidades/dados/tipos-classe-tarifaria/listar")
          .then((response) => {

            let options_classe_tarifaria = [];
            let desc_classe_tarifaria = {};
            response.data.dados.map((el_classe_tarifaria) => {

              let option_classe_tarifaria = { value: el_classe_tarifaria["id"], label: el_classe_tarifaria["descricao"] };
              desc_classe_tarifaria[el_classe_tarifaria["id"]] = el_classe_tarifaria["descricao"];
              options_classe_tarifaria.push(option_classe_tarifaria);

            });
            this.tipos_classe_tarifaria = options_classe_tarifaria;
            this.desc_tipos_classe_tarifaria = desc_classe_tarifaria;

            console.log(this.desc_tipos_classe_tarifaria)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarSubclasse() {
        this.axios
          .post("/unidades/dados/tipos-sub-classe/listar")
          .then((response) => {

            let options_subclasse = [];
            let desc_subclasse = {};
            response.data.dados.map((el_subclasse) => {

              let option_subclasse = { value: el_subclasse["id"], label: el_subclasse["descricao"] };
              desc_subclasse[el_subclasse["id"]] = el_subclasse["descricao"];
              options_subclasse.push(option_subclasse);

            });
            this.tipos_subclasse = options_subclasse;
            this.desc_tipos_subclasse = desc_subclasse;

            console.log(this.desc_tipos_subclasse)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarTensao() {
        this.axios
          .post("/unidades/dados/tipos-tensao/listar")
          .then((response) => {

            let options_tensao = [];
            let desc_tensao = {};
            response.data.dados.map((el_tensao) => {

              let option_tensao = { value: el_tensao["id"], label: el_tensao["descricao"] };
              desc_tensao[el_tensao["id"]] = el_tensao["descricao"];
              options_tensao.push(option_tensao);

            });
            this.tipos_tensao = options_tensao;
            this.desc_tipos_tensao = desc_tensao;

            console.log(this.desc_tipos_tensao)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarLigacao() {
        this.axios
          .post("/unidades/dados/tipos-ligacao/listar")
          .then((response) => {

            let options_ligacao = [];
            let desc_ligacao = {};
            response.data.dados.map((el_ligacao) => {

              let option_ligacao = { value: el_ligacao["id"], label: el_ligacao["descricao"] };
              desc_ligacao[el_ligacao["id"]] = el_ligacao["descricao"];
              options_ligacao.push(option_ligacao);

            });
            this.tipos_ligacao = options_ligacao;
            this.desc_tipos_ligacao = desc_ligacao;

            console.log(this.desc_tipos_ligacao)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      

      
    }
  }